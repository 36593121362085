<template>
  <div class="wrap">
    <FlowInfo
      :page="page"
      :processDefinitionId="processDefinitionId"
    ></FlowInfo>
  </div>
</template>

<script>
import {
  getActiveList,
  getHistoryFlowList,
  getMultipleNodes,
  getProcessInstance,
  retractFlow,
} from "@/api/flow";
import { formatTime } from "@/utils/DateUtil";

import FlowInfo from "@/components/FlowInfo";

export default {
  components: { FlowInfo },
  data() {
    return {
      isLoading: false,
      active: 0,
      variables: {
        status: 0,
      },
      processInstanceId: null,
      processDefinitionId: null,
      src: "",
      renderedStartForm: {},
      formData: {},
      variablesArr: [],
      taskList: [],
      imageJson: {},
      detailForm: {
        rule: [],
        option: {},
        value: {},
      },
      fApi: {},
      activityList: [],
      bpmnControlForm: {
        prefix: "flowable",
      },
      processInstance: {},
      tasks: [],
      bpmnXml: "",
      bpmnModeler: null,
      formList: [],

      isNewFc: false,

      jsonData: {},
      jsonOtherData: {},

      page: "history",
    };
  },
  watch: {},
  async mounted() {
    this.processInstanceId = this.$route.query.id;
    this.processDefinitionId = this.$route.query.processDefinitionId;
    this.page = this.$route.query.page || "history";
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 0 10px;

  .title {
    display: flex;
    align-items: center;
    .line {
      width: 0.2rem;
      height: 0.8rem;
      background: #037ae7;
      margin-right: 10px;
    }
  }
}
</style>
